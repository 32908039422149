(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$timeout', 'AlertService', 'Auth', '$rootScope', '$http', '$interval', 'NgMap', '$window', '$location'];

    function HomeController ($scope, Principal, LoginService, $state, $timeout, AlertService, Auth, $rootScope, $http, $interval, NgMap, $window, $location) {
        var vm = this;

        vm.authenticationError = false;
        //vm.cancel = cancel;
        vm.credentials = {};
        vm.login2 = login2;
        vm.loginWithMultiFactor = loginWithMultiFactor;
        vm.password = null;
        //vm.register = register;
        vm.rememberMe = true;
        vm.username = null;
        vm.code = null;
        vm.showCode = false;

        $scope.showDays = true;
        $scope.showMonths = false;

        $scope.hourDisabled = true;
        $scope.massDisabled = true;
        $scope.equipmentHourDisabled = true;
        $scope.openedProjects = [];

        $scope.startDate = moment();

        // Filter bar and search bar
        $scope.filterString = '';
        $scope.searchFilter = 'ALLE';
        $scope.filterLetters = ['ALLE', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
            'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Æ', 'Ø', 'Å'];
        $scope.status = {
            // We are showing projects that are in progress by default.
            selected: 'IN_PROGRESS'
        };
        $scope.type = {
            // We are showing only 'Investering' projects by default.
            // However, ROLE_USERS will have this set to NONE later (see getAccount() below).
            selected: 'INVESTMENT'
        };
        $scope.limit = {
            // Currently not used (not dropdown in GUI. Default to showing all projects.
            selected: 'NONE'
        };

        $scope.projects = [];
        $scope.openedProjects = [];

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;

        $timeout(function (){angular.element('#username').focus();});

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            loadData();

            // Reload view on login. This will force a reload of the navbar.
            $state.reload();
        });

        getAccount();
        //loadData();
        //getAvailableMembers();
        //getAvailableMasses();
        //getAvailableEquipment();
        clearProjectInput();

        function loadData() {
            var url = '/api/projects?status=' + $scope.status.selected
                + '&filterLetter=' + $scope.searchFilter
                + '&type=' + $scope.type.selected
                + '&limit=' + $scope.limit.selected;
            $http.get(url).then(function (result) {
                $scope.projects = result.data;
                angular.forEach($scope.projects, function (value, key) {
                    value.selectedDate = moment();
                    value.previewImages = [];
                    value.filesToUpload = [];
                });
            });
        }

        // TODO: Remove
        /*
        function getAvailableMasses() {
            $http.get('/api/availableMasses').then(function (result) {
                $scope.availableMasses = result;
            });
        }
        */

        // TODO: Remove
        /*
        function getAvailableMembers() {
            $http.get('/api/availableMembers').then(function (result) {
                $scope.availableMembers = result;
            });
        }
        */

        // TODO: Remove
        /*
        function getAvailableEquipment() {
            $http.get('/api/availableEquipment').then(function (result) {
                $scope.availableEquipment = result;
            });
        }
        */

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account) {
                    // ROLE_USER and ROLE_LEADER will not have that many projects, so show all types.
                    if (vm.account.authorities.includes('ROLE_USER') || vm.account.authorities.includes('ROLE_LEADER')) {
                        $scope.type.selected = 'NONE';
                    }

                    // Read the status filter property from local storage (if any).
                    if (localStorage.getItem('status') != null) {
                        $scope.status.selected = localStorage.getItem('status');
                    }

                    // Read the type filter property from local storage (if any).
                    if (localStorage.getItem('type') != null) {
                        $scope.type.selected = localStorage.getItem('type');
                    }

                    loadData();
                }
            });
        }

        /*
        function register () {
            $state.go('register');
        }
        */

        $scope.forgotPassword = function() {
            $state.go('requestReset');
        };

        $scope.goToFavourites = function() {
            $state.go('favourite');
        };

        $scope.dismissChangeNote = function() {
            $http({
                method: 'POST',
                url: 'api/changenote/dismiss'
            }).then(function (response) {
                // TODO: Anything to do???
            }, function (response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        // TODO: Remove
        /*
        $scope.broadcast = function(project) {
            $timeout(function() {
              $scope.$broadcast('rzSliderForceRender');
            });

            // Only do map initialization and getting all hours and amounts for project once
            if ($scope.openedProjects.indexOf(project.id) === -1) {
                // Initialize map
                NgMap.getMap({id: 'map-project-id-' + project.id}).then(function(map) {
                    var lngOffset = 0.023;
                    if ($window.innerWidth < 768) {
                        lngOffset = 0.010;
                    }
                    var lat = parseFloat(project.gpsLocation.latitude) + 0.0035;
                    var lng = parseFloat(project.gpsLocation.longitude) - lngOffset;
                    var latlng = new google.maps.LatLng(lat, lng);
                    map.setCenter(latlng);

                    $timeout(function() {
                        google.maps.event.trigger(map, 'resize')
                    }, 250);
                });

                // Get all member hours
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                            project.allMemberHours = result.projectMember;
                            project.allMassAmounts = result.projectMass;
                            project.allEquipmentHours = result.projectEquipment;
                        });
                    }
                });
            }

            // This project has now been opened
            $scope.openedProjects.push(project.id);
        };
        */

        $scope.getImageUrl = function(imageUrl, project) {
            if ($scope.openedProjects.indexOf(project.id) > -1) {
                return imageUrl;
            }
            return null;
        };

        // Will be invoked when the status or type dropdown is changed
        $scope.filterChanged = function(value, filterProperty) {
            // Save the current selection to local storage and load data.
            localStorage.setItem(filterProperty, value);
            loadData();
        };

        $scope.applyFilter = function(letter) {
            $scope.searchFilter = letter;
            loadData();
        };

        $scope.reloadData = function() {
            loadData();
        };

        // TODO: Remove
        $scope.updateSelectedDate = function(project) {
            $http.get('/api/projects/' + project.id + "/" + project.selectedDate.format('YYYY-MM-DD'))
                .then(function(result) {
                    project.members = result.data.members;
                    project.masses = result.data.masses;
                    project.equipment = result.data.equipment;
            });
        };

        // TODO: Remove
        $scope.calculateSumOfMemberHours = function(project) {
            var sum = 0;
            angular.forEach(project.members, function(value, key){
                sum += value.amount;
            });
            return sum;
        }

        // TODO: Remove
        $scope.calculateSumOfMassAmounts = function(project) {
            var sum = 0;
            angular.forEach(project.masses, function(value, key){
                sum += value.amount;
            });
            return sum;
        }


        // TODO: Remove
        $scope.calculateSumOfEquipmentHours = function(project) {
            var sum = 0;
            angular.forEach(project.equipment, function(value, key){
                sum += value.amount;
            });
            return sum;
        }

        // TODO: Remove
        $scope.addMember = function(project, member) {
            var found = false;
            angular.forEach(project.members, function(value, key) {
                if (value.id === member.id) {
                    found = true;
                }
            });

            // Member already part of project, nothing to be done
            if (!found) {
                $http({
                    method: 'POST',
                    url: 'api/projects/' + project.id + '/members',
                    data: {
                        memberId: member.id,
                        date: project.selectedDate.format('YYYY-MM-DD'),
                        hours: 0,
                        //TODO: Implement proper member cost
                        unitCost: 500
                    }
                }).then(function (response) {
                    // TODO: Or get this from server instead?
                    project.members.push({
                        id: member.id,
                        name: member.name,
                        amount: 0
                    });
                    project.projectMemberList.push(member.name);
                }, function (response) {
                    alert("En feil oppstod. Sjekk loggen!");
                    console.log(response);
                });
            }
        };


        // TODO: Remove
        $scope.addMass = function(project, mass) {
            var found = false;
            angular.forEach(project.masses, function(value, key) {
                if (value.id === mass.id) {
                    found = true;
                }
            });

            if (!found) {
                $http({
                    method: 'POST',
                    url: 'api/projects/' + project.id + '/masses',
                    data: {
                        massId: mass.id,
                        date: project.selectedDate.format('YYYY-MM-DD'),
                        amount: 0,
                        unitCost: mass.price
                    }
                }).then(function(response) {
                    // TODO: Or get this from server instead?
                    project.masses.push({
                        id: mass.id,
                        name: mass.type,
                        unit: mass.unit,
                        amount: 0
                    });
                }, function(response) {
                    alert("En feil oppstod. Sjekk loggen!");
                    console.log(response);
                });
            }

        }

        // TODO: Remove
        $scope.addEquipment = function(project, equipment) {
            var found = false;
            angular.forEach(project.equipment, function(value, key) {
                if (value.id === equipment.id) {
                    found = true;
                }
            });

            if (!found) {
                $http({
                    method: 'POST',
                    url: 'api/projects/' + project.id + '/equipment',
                    data: {
                        equipmentId: equipment.id,
                        date: project.selectedDate.format('YYYY-MM-DD'),
                        hours: 0,
                        unitCost: equipment.price
                    }
                }).then(function(response) {
                    // TODO: Or get this from server instead?
                    project.equipment.push({
                        id: equipment.id,
                        name: equipment.type,
                        amount: 0
                    });
                }, function(response) {
                    alert("En feil oppstod. Sjekk loggen!");
                    console.log(response);
                });
            }

        }

        /* === Functions for saving member hours, masses and equipment hours ======================================== */

        $scope.saveHours = function(project) {
            $http({
                method: 'POST',
                url: 'api/projects/' + project.id + '/hours',
                data: {
                    date: project.selectedDate.format('YYYY-MM-DD'),
                    members: project.members
                }
            }).then(function(response) {
                project.summary = response.data.summary;
                AlertService.success("Timer er registrert");
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        }

        $scope.saveMasses = function(project) {
            $http({
                method: 'POST',
                url: 'api/projects/' + project.id + '/masses/amount',
                data: {
                    date: project.selectedDate.format('YYYY-MM-DD'),
                    massesAmount: project.masses
                }
            }).then(function(response) {
                project.summary = response.data.summary;
                AlertService.success("Masse er registrert");
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.saveProduct = function() {
            var project = $scope.newProduct.project;
            $http({
                method: 'POST',
                url: 'api/projects/' + $scope.newProduct.project.id + '/product',
                data: {
                    name: $scope.newProduct.name,
                    price: $scope.newProduct.price
                }
            }).then(function(response) {
                project.products = response.data.products;
                project.summary = response.data.summary;
                AlertService.success("Produkt er registrert");
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
            $('#newProductModal').modal('hide');
        };

        $scope.saveEquipment = function(project) {
            $http({
                method: 'POST',
                url: 'api/projects/' + project.id + '/equipment/hours',
                data: {
                    date: project.selectedDate.format('YYYY-MM-DD'),
                    equipmentHours: project.equipment
                }
            }).then(function(response) {
                project.summary = response.data.summary;
                AlertService.success("Timer er registert på utstyr");
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        }

        $scope.saveImages  = function(project) {
            // Upload all images
            var i;
            for (i = 0; i < project.previewImages.length; i++) {
                var formData = new FormData();
                formData.append('file', project.filesToUpload[i]);
                formData.append('comment', project.previewImages[i].comment);
                formData.append('projectId', project.id);
                $http.post('/api/uploadImage', formData, {
                    headers: {'Content-Type': undefined}
                }).then(function(result) {
                    project.previewImages = [];
                    project.images = result.data.images;
                    project.filesToUpload = [];
                    AlertService.success("Bilder er lastet opp");
                })
            }
        };

        $scope.saveProject = function() {
            // TODO: Setting department to BYDRIFT for now
            $scope.newProject.department = 'BYDRIFT';
            $http({
                method: 'POST',
                url: 'api/projects',
                data: $scope.newProject
            }).then(function(response) {
                var project = response.data;
                project.selectedDate = moment();
                project.previewImages = [];
                project.filesToUpload = [];
                $scope.projects.push(project);
                clearProjectInput();
                $('#newProjectModal').modal('hide');

                // Wait a little bit to make the modal window close properly first
                $timeout(function() {
                    $state.go('project', { id: project.id });
                }, 400);
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#newProjectModal').modal('hide');
            });
        };

        $scope.onDocumentSelected = function(event) {
            $scope.newDocument.file = event.target.files[0];
        };

        $scope.saveDocument = function() {
            var project = $scope.newDocument.project;
            var formData = new FormData();
            formData.append('file', $scope.newDocument.file);
            formData.append('title', $scope.newDocument.name);
            formData.append('comment', $scope.newDocument.description);
            formData.append('projectId', project.id);
            $http.post('/api/uploadDocument', formData, {
                headers: {'Content-Type': undefined}
            }).then(function(result) {
                $('#newDocumentModal').modal('hide');
                AlertService.success("Dokumentet er lastet opp");
                project.documents = result.data.documents;
            }, function(result) {
                $('#newDocumentModal').modal('hide');
                AlertService.error("En feil oppstod. Dokumentet er ikke lastet opp.");
                console.log('An error occurred when uploading document');
                console.log(result);
            });
            document.getElementById("inputDocumentFile").value = "";
        };

        $scope.saveProjectLog = function () {
            var project = $scope.newProjectLog.project;
            var formData = new FormData();
            if ($scope.newProjectLog.filesToUpload.length > 0) {
                formData.append('file', $scope.newProjectLog.filesToUpload[0]);
            }
            formData.append('comment', $scope.newProjectLog.comment);
            formData.append('projectId', project.id);
            formData.append('logNo', $scope.newProjectLog.logNo);
            formData.append('dateAndTime', $scope.newProjectLog.dateAndTime.format('YYYY-MM-DD HH:mm'));
            formData.append('responsible', $scope.newProjectLog.responsible);
            formData.append('whatHasChanged', $scope.newProjectLog.whatHasChanged);
            formData.append('type', $scope.newProjectLog.type);
            $http.post('/api/uploadImageWithLog', formData, {
                headers: {'Content-Type': undefined}
            }).then(function(result) {
                project.logs = result.data.logs;
                $('#newLogModal').modal('hide');
                AlertService.success("Ny oppføring i loggboken er lagret");
            }, function(result) {
                $('#newLogModal').modal('hide');
                AlertService.error("En feil oppstod. Loggen er ikke oppdatert!");
                console.log('An error occurred when uploading project log');
                console.log(result);
            });
        };

        /* Use REST-endpoint to save work log. */
        $scope.saveWorkLog = function () {
            var project = $scope.newWorkLog.project;
            var formData = new FormData();
            if ($scope.newWorkLog.filesToUpload.length > 0) {
                formData.append('file', $scope.newWorkLog.filesToUpload[0]);
            }
            formData.append('comment', $scope.newWorkLog.comment);
            formData.append('projectId', project.id);
            formData.append('dateAndTime', $scope.newWorkLog.dateAndTime.format('YYYY-MM-DD HH:mm'));
            formData.append('type', $scope.newWorkLog.type);
            formData.append('logType', $scope.newWorkLog.logType);
            formData.append('address', $scope.newWorkLog.address);
            formData.append('responsible', $scope.newWorkLog.responsible);
            $http.post('/api/uploadImageWithLog', formData, {
                headers: {'Content-Type': undefined}
            }).then(function(result) {
                project.workLogs = result.data.workLogs;
                $('#newWorkLogModal').modal('hide');
                AlertService.success("Ny loggbok for arbeidsvarsling lagret");
            }, function(result) {
                $('#newWorkLogModal').modal('hide');
                AlertService.error("En feil oppstod. Loggen er ikke oppdatert!");
                console.log('An error occurred when uploading project log');
                console.log(result);
            });
        };

        function clearProjectInput() {
            $scope.newProject = {
                projectMemberList: [],
                summary: {
                    memberHours: 0,
                    equipmentHours: 0,
                    estimatedCost: 0,
                    massCost: 0
                },
                members: [],
                masses: [],
                equipment: [],
                documents: [],
                images: []
            };
        }

        $scope.addImages = function(event) {
            var files = event.target.files;

            // Find project from project list
            var projectId = event.target.id.split('-')[1];
            var project = {};
            angular.forEach($scope.projects, function(value, key) {
                // Note === will not work here!
                if (value.id == projectId) {
                    project = value;
                }
            });

            var i;
            for (i = 0; i < files.length; i++) {

                // Finding orientation of image
                var orientation = null;
                EXIF.getData(files[i], function() {
                    orientation = EXIF.getTag(this, "Orientation");
                });

                var fileReader = new FileReader();

                // Resize image
                fileReader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {

                        // Resize the image
                        var canvas = document.createElement('canvas');
                        var max_size = 1200; // TODO : pull max size from a site config

                        var width, height;
                        if (orientation === 8 || orientation === 6) {
                            // For orientation 8 and 6 we need to flip the width and height
                            width = image.height;
                            height = image.width;
                        } else {
                            width = image.width;
                            height = image.height;
                        }

                        // Resize according to max_size
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        var context = canvas.getContext('2d');
                        context.imageSmoothingEnabled = true;

                        if (orientation === 8 || orientation === 6 || orientation === 3) {
                            // move to the center of the canvas
                            context.translate(canvas.width / 2, canvas.height / 2);

                            if (orientation === 8) {
                                context.rotate(-90 * Math.PI / 180);
                            } else if (orientation === 6) {
                                context.rotate(90 * Math.PI / 180);
                            } else {
                                context.rotate(180 * Math.PI / 180);
                            }
                        }

                        // Draw the image
                        if (orientation === 8 || orientation === 6) {
                            context.drawImage(image, -canvas.height / 2, -canvas.width / 2, height, width);
                        } else if (orientation === 3) {
                            context.drawImage(image, -canvas.width / 2, -canvas.height / 2, width, height);
                        } else {
                            context.drawImage(image, 0, 0, width, height)
                        }

                        var dataUrl = canvas.toDataURL('image/jpeg');
                        var resizedImage = dataURLToBlob(dataUrl);
                        project.filesToUpload.push(resizedImage);

                        project.previewImages.push({
                            imageUrl: dataUrl,
                            comment: ''
                        });
                        $scope.$apply();
                    };
                    image.src = readerEvent.target.result;
                };
                fileReader.readAsDataURL(files[i]);
            }

            $scope.$apply();
        };

        // XXX: Refactor me please!!!! A lot of copy and paste from addImages()
        $scope.addImageToProjectLog = function(event) {
            var files = event.target.files;

            // Find project from project list
            var projectId = event.target.id.split('-')[1];
            var project = {};
            angular.forEach($scope.projects, function(value, key) {
                // Note === will not work here!
                if (value.id == projectId) {
                    project = value;
                }
            });

            var i;
            for (i = 0; i < files.length; i++) {

                // Finding orientation of image
                var orientation = null;
                EXIF.getData(files[i], function() {
                    orientation = EXIF.getTag(this, "Orientation");
                });

                var fileReader = new FileReader();

                // Resize image
                fileReader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {

                        // Resize the image
                        var canvas = document.createElement('canvas');
                        var max_size = 1200; // TODO : pull max size from a site config

                        var width, height;
                        if (orientation === 8 || orientation === 6) {
                            // For orientation 8 and 6 we need to flip the width and height
                            width = image.height;
                            height = image.width;
                        } else {
                            width = image.width;
                            height = image.height;
                        }

                        // Resize according to max_size
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        var context = canvas.getContext('2d');
                        context.imageSmoothingEnabled = true;

                        if (orientation === 8 || orientation === 6 || orientation === 3) {
                            // move to the center of the canvas
                            context.translate(canvas.width / 2, canvas.height / 2);

                            if (orientation === 8) {
                                context.rotate(-90 * Math.PI / 180);
                            } else if (orientation === 6) {
                                context.rotate(90 * Math.PI / 180);
                            } else {
                                context.rotate(180 * Math.PI / 180);
                            }
                        }

                        // Draw the image
                        if (orientation === 8 || orientation === 6) {
                            context.drawImage(image, -canvas.height / 2, -canvas.width / 2, height, width);
                        } else if (orientation === 3) {
                            context.drawImage(image, -canvas.width / 2, -canvas.height / 2, width, height);
                        } else {
                            context.drawImage(image, 0, 0, width, height)
                        }

                        var dataUrl = canvas.toDataURL('image/jpeg');
                        var resizedImage = dataURLToBlob(dataUrl);
                        $scope.newProjectLog.filesToUpload.push(resizedImage);
                    };
                    image.src = readerEvent.target.result;
                };
                fileReader.readAsDataURL(files[i]);
            }
        };

        // XXX: Refactor me please!!!! A lot of copy and paste from addImages()
        $scope.addImageToWorkLog = function(event) {
            var files = event.target.files;

            // Find project from project list
            var projectId = event.target.id.split('-')[1];
            var project = {};
            angular.forEach($scope.projects, function(value, key) {
                // Note === will not work here!
                if (value.id == projectId) {
                    project = value;
                }
            });

            var i;
            for (i = 0; i < files.length; i++) {

                // Finding orientation of image
                var orientation = null;
                EXIF.getData(files[i], function() {
                    orientation = EXIF.getTag(this, "Orientation");
                });

                var fileReader = new FileReader();

                // Resize image
                fileReader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {

                        // Resize the image
                        var canvas = document.createElement('canvas');
                        var max_size = 1200; // TODO : pull max size from a site config

                        var width, height;
                        if (orientation === 8 || orientation === 6) {
                            // For orientation 8 and 6 we need to flip the width and height
                            width = image.height;
                            height = image.width;
                        } else {
                            width = image.width;
                            height = image.height;
                        }

                        // Resize according to max_size
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        var context = canvas.getContext('2d');
                        context.imageSmoothingEnabled = true;

                        if (orientation === 8 || orientation === 6 || orientation === 3) {
                            // move to the center of the canvas
                            context.translate(canvas.width / 2, canvas.height / 2);

                            if (orientation === 8) {
                                context.rotate(-90 * Math.PI / 180);
                            } else if (orientation === 6) {
                                context.rotate(90 * Math.PI / 180);
                            } else {
                                context.rotate(180 * Math.PI / 180);
                            }
                        }

                        // Draw the image
                        if (orientation === 8 || orientation === 6) {
                            context.drawImage(image, -canvas.height / 2, -canvas.width / 2, height, width);
                        } else if (orientation === 3) {
                            context.drawImage(image, -canvas.width / 2, -canvas.height / 2, width, height);
                        } else {
                            context.drawImage(image, 0, 0, width, height)
                        }

                        var dataUrl = canvas.toDataURL('image/jpeg');
                        var resizedImage = dataURLToBlob(dataUrl);
                        $scope.newWorkLog.filesToUpload.push(resizedImage);
                    };
                    image.src = readerEvent.target.result;
                };
                fileReader.readAsDataURL(files[i]);
            }
        };

        /* Utility function to convert a canvas to a BLOB */
        var dataURLToBlob = function(dataURL) {
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                var parts = dataURL.split(',');
                var contentType = parts[0].split(':')[1];
                var raw = parts[1];

                return new Blob([raw], {type: contentType});
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: contentType});
        };
        /* End Utility function to convert a canvas to a BLOB      */

        var isPortrait = function(img) {
            var w = img.naturalWidth || img.width,
                h = img.naturalHeight || img.height;
            return (h > w);
        }

        $scope.setGpsLocation = function(project) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    if (!project) {
                        $scope.$apply(function(){
                            $scope.newProject.gpsLocation = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        });
                    } else {
                        $scope.$apply(function(){
                            project.gpsLocation.latitude = position.coords.latitude;
                            project.gpsLocation.longitude = position.coords.longitude;
                        });
                    }
                }, function showError(error) {
                    alert("Ikke mulig å hente lokasjonen din nå (" + error + ")");
                });
            }
        };

        $scope.setGpsLocationBasedOnAddress = function() {
            if ($scope.newProject.address && $scope.newProject.postLocation) {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'address': $scope.newProject.address + ', ' + $scope.newProject.postLocation + ', Norway'
                }, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
                            console.log("fkdjshkf");
                            $scope.$apply(function(){
                                $scope.newProject.gpsLocation = {
                                    latitude: results[0].geometry.location.lat(),
                                    longitude: results[0].geometry.location.lng()
                                }
                            });
                        } else {
                            alert("No results found");
                        }
                    } else {
                        alert("Geocode was not successful for the following reason: " + status);
                    }
                });
            }
        };

        /* === DELETE AND REMOVE ==================================================================================== */

        $scope.removeMemberFromProject = function () {
            var project = $scope.memberToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/members/' + $scope.memberToDelete.memberId,
                data: null
            }).then(function(response) {
                project.selectedDate = moment();
                project.summary = response.data.summary;
                project.members = response.data.members;
                $('#confirmDeleteMemberModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteMemberModal').modal('hide');
            });
        };

        $scope.removeMassFromProject = function() {
            var project = $scope.massToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/masses/' + $scope.massToDelete.massId,
                data: null
            }).then(function(response) {
                project.selectedDate = moment();
                project.summary = response.data.summary;
                project.masses = response.data.masses;
                $('#confirmDeleteMassModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteMassModal').modal('hide');
            });
        }

        $scope.removeEquipmentFromProject = function() {
            var project = $scope.equipmentToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/equipment/' + $scope.equipmentToDelete.equipmentId,
                data: null
            }).then(function(response) {
                project.selectedDate = moment();
                project.summary = response.data.summary;
                project.equipment = response.data.equipment;
                $('#confirmDeleteEquipmentModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteEquipmentModal').modal('hide');
            });
        }

        $scope.deleteProject = function () {
            var project = $scope.projectToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id,
                data: null
            }).then(function(response) {
                var index = $scope.projects.indexOf(project);
                $scope.projects.splice(index, 1);
                $('#confirmDeleteProjectModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteProjectModal').modal('hide');
            });
        };

        /* Delete product using REST endpoint. Project will be updated on successful response */
        $scope.deleteProduct = function () {
            var project = $scope.productToDelete.project;
            var product = $scope.productToDelete.product;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/products/' + product.id,
                data: null
            }).then(function(response) {
                project.products = response.data.products;
                project.summary = response.data.summary;
                $('#confirmDeleteProductModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteProductModal').modal('hide');
            });
        };

        /* Delete document using REST endpoint */
        $scope.deleteDocument = function () {
            var project = $scope.documentToDelete.project;
            var document = $scope.documentToDelete.document;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/documents/' + document.id,
                data: null
            }).then(function(response) {
                project.documents = response.data.documents;
                $('#confirmDeleteDocumentModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteDocumentModal').modal('hide');
            });
        };

        /* Delete preview image from project */
        $scope.deletePreviewImage = function (project, image) {
            var index = project.previewImages.indexOf(image);
            project.previewImages.splice(index, 1);
            // TODO: Need to verify that this will remove correct image
            project.filesToUpload.splice(index, 1);
        };

        /* Delete image using REST endpoint */
        $scope.deleteImage = function () {
            var project = $scope.imageToDelete.project;
            var image = $scope.imageToDelete.image;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/images/' + image.id,
                data: null
            }).then(function(response) {
                project.images = response.data.images;
                $('#confirmDeleteImageModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteImageModal').modal('hide');
            });
        };

        /* Delete log using REST endpoint */
        $scope.deleteLog = function () {
            var project = $scope.logToDelete.project;
            var log = $scope.logToDelete.log;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/logs/' + log.id,
                data: null
            }).then(function(response) {
                project.logs = response.data.logs;
                $('#confirmDeleteLogModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteLogModal').modal('hide');
            });
        };

        /* Delete work log using REST endpoint */
        $scope.deleteWorkLog = function () {
            var project = $scope.workLogToDelete.project;
            var log = $scope.workLogToDelete.log;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/logs/' + log.id,
                data: null
            }).then(function(response) {
                project.workLogs = response.data.workLogs;
                $('#confirmDeleteWorkLogModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteWorkLogModal').modal('hide');
            });
        };

        $scope.removeFavourite = function(project) {
            $http({
                method: 'POST',
                url: 'api/favourite/' + project.id + '/false'
            }).then(function(response) {
                loadData();
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        /* === MODAL ================================================================================================ */

        $scope.showAddProjectModal = function() {
            $scope.newProject = {
                status: 'NEW',
                type: 'INVESTMENT',
                invoiceType: 'HOURLY_RATE',
                projectMemberList: [],
                summary: {
                    memberHours: 0,
                    equipmentHours: 0,
                    estimatedCost: 0,
                    massCost: 0
                },
                members: [],
                masses: [],
                equipment: [],
                documents: [],
                images: []
            };
            $('#newProjectModal').modal('show');
        };

        $scope.findNextAvailableProjectNumber = function() {
            if ($scope.newProject.type === 'CUSTOM') {
                $http.get('api/nextAvailableProjectNumber')
                    .then(function (result) {
                        $scope.newProject.projectNo = result.data;
                    });
            } else {
                $scope.newProject.projectNo = "";
            }
        };

        $scope.showAddImagesModal = function(project) {
            $('#addImagesModal').modal('show');
        };

        $scope.showAddDocumentModal = function(project) {
            $scope.newDocument = {};
            $scope.newDocument.project = project;
            $('#newDocumentModal').modal('show');
        };

        $scope.showAddProductModal = function (project) {
            $scope.newProduct = {};
            $scope.newProduct.project = project;
            $('#newProductModal').modal('show');
        };

        $scope.showAddProjectLogModal = function (project) {
            $scope.newProjectLog = {};
            $scope.newProjectLog.dateAndTime = moment();
            $scope.newProjectLog.project = project;
            $scope.newProjectLog.filesToUpload = [];
            $scope.newProjectLog.type = 'LOG';
            $('#newLogModal').modal('show');
        };

        $scope.showAddWorkLogModal = function (project) {
            $scope.newWorkLog = {};
            var account = vm.account;
            if (account.firstName || account.lastName) {
                $scope.newWorkLog.responsible = account.firstName + ' ' + account.lastName;
            }
            $scope.newWorkLog.dateAndTime = moment();
            $scope.newWorkLog.project = project;
            $scope.newWorkLog.filesToUpload = [];
            $scope.newWorkLog.type = 'WORK_LOG';
            // Default value for log type
            $scope.newWorkLog.logType = 'Skilt satt ut';
            $('#newWorkLogModal').modal('show');
        };

        $scope.showDeleteMemberModal = function (project, member) {
            $scope.memberToDelete = {};
            $scope.memberToDelete.name = member.name;
            $scope.memberToDelete.project = project;
            $scope.memberToDelete.memberId = member.id;
            $('#confirmDeleteMemberModal').modal('show');
        };

        $scope.showDeleteMassModal = function (project, mass) {
            $scope.massToDelete = {};
            $scope.massToDelete.name = mass.name;
            $scope.massToDelete.project = project;
            $scope.massToDelete.massId = mass.id;
            $('#confirmDeleteMassModal').modal('show');
        };

        $scope.showDeleteEquipmentModal = function (project, equipment) {
            $scope.equipmentToDelete = {};
            $scope.equipmentToDelete.name = equipment.name;
            $scope.equipmentToDelete.project = project;
            $scope.equipmentToDelete.equipmentId = equipment.id;
            $('#confirmDeleteEquipmentModal').modal('show');
        };

        $scope.showDeleteProjectModal = function (project) {
            $scope.projectToDelete = {};
            $scope.projectToDelete.project = project;
            $('#confirmDeleteProjectModal').modal('show');
        };

        $scope.showDeleteProductModal = function (project, product) {
            $scope.productToDelete = {};
            $scope.productToDelete.project = project;
            $scope.productToDelete.product = product;
            $('#confirmDeleteProductModal').modal('show');
        };

        $scope.showDeleteDocumentModal = function (project, document) {
            $scope.documentToDelete = {};
            $scope.documentToDelete.project = project;
            $scope.documentToDelete.document = document;
            $('#confirmDeleteDocumentModal').modal('show');
        };

        $scope.showDeleteImageModal = function (project, image) {
            $scope.imageToDelete = {};
            $scope.imageToDelete.project = project;
            $scope.imageToDelete.image = image;
            $('#confirmDeleteImageModal').modal('show');
        };

        $scope.showDeleteLogModal = function (project, log) {
            $scope.logToDelete = {};
            $scope.logToDelete.project = project;
            $scope.logToDelete.log = log;
            $('#confirmDeleteLogModal').modal('show');
        };

        $scope.showDeleteWorkLogModal = function (project, log) {
            $scope.workLogToDelete = {};
            $scope.workLogToDelete.project = project;
            $scope.workLogToDelete.log = log;
            $('#confirmDeleteWorkLogModal').modal('show');
        };

        // TODO: Remove (replaced by loginWithMultiFactor).
        function login2 (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                //$uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                /*
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
                 */

                // Reset username and password fields
                vm.username = null;
                vm.password = null;
            }).catch(function () {
                vm.authenticationError = true;
                vm.password = null;
            });
        }

        function loginWithMultiFactor (event) {
            event.preventDefault();
            Auth.loginWithMultiFactor({
                // Username should always be lowercase.
                username: vm.username.toLowerCase(),
                password: vm.password,
                rememberMe: vm.rememberMe,
                code: vm.code
            }).then(function (data) {
                vm.authenticationError = false;

                // Show code field if not visible.
                if (!vm.showCode) {
                    vm.showCode = true;
                    $timeout(function (){angular.element('#code').focus();});
                }

                // If code has been provided and authentication is success => broadcast authentication success.
                if (vm.code || angular.isString(data)) {
                    $rootScope.$broadcast('authenticationSuccess');
                    vm.username = null;
                    vm.password = null;
                    vm.code = null;
                }
            }).catch(function () {
                vm.authenticationError = true;
                if (!vm.showCode) {
                    vm.password = null;
                }
                vm.code = null;
            });
        }

    }
})();
